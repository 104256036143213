import ClassicEditorBase from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import Link from "@ckeditor/ckeditor5-link/src/link";
import CKFinder from "@ckeditor/ckeditor5-ckfinder/src/ckfinder";
import CKFinderUploadAdapter from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter";
import List from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation";
import ImageResizeEditing from "@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting";
import ImageResizeHandles from "@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles";
import ImageCustomResizeUI from "@ckeditor/ckeditor5-image/src/imageresize/imagecustomresizeui";
import ResizeImageCommand from "@ckeditor/ckeditor5-image/src/imageresize/resizeimagecommand";
import ImageResizeButtons from "@ckeditor/ckeditor5-image/src/imageresize/imageresizebuttons";
import MathType from "@wiris/mathtype-ckeditor5";

export default class ClassicEditor extends ClassicEditorBase {}

ClassicEditor.builtinPlugins = [
	Essentials,
	Autoformat,
	Bold,
	Italic,
	BlockQuote,
	Heading,
	Indent,
	Link,
	List,
	MediaEmbed,
	Paragraph,
	PasteFromOffice,
	SimpleUploadAdapter,
	Image,
	ImageToolbar,
	ImageResize,
	ImageStyle,
	ImageCaption,
	ImageUpload,
	Table,
	TableToolbar,
	TextTransformation,
	MathType,
	ImageResizeEditing,
	ImageResizeHandles,
	ImageResizeButtons,
	ImageCustomResizeUI,
	ResizeImageCommand,
	CKFinder,
	CKFinderUploadAdapter,
];

ClassicEditor.defaultConfig = {
	toolbar: {
		items: [
			"heading",
			"|",
			"bold",
			"italic",
			"link",
			"|",
			"indent",
			"outdent",
			"|",
			"insertTable",
			"imageUpload",
			"ckfinder",
			"blockQuote",
			"mediaEmbed",
			"MathType",
			"ChemType",
			"undo",
			"redo",
		],
	},
	image: {
		toolbar: [
			"imageStyle:inline",
			"imageStyle:block",
			"imageStyle:side",
			"resizeImage",
			"resizeImage:custom",
			// "imageUpload",
		],
		resizeOptions: [
			{ name: "resizeImage:original", label: "Original", value: null },
			{ name: "resizeImage:10", label: "10%", value: "10" },
			{ name: "resizeImage:20", label: "20%", value: "20" },
			{ name: "resizeImage:30", label: "30%", value: "30" },
			{ name: "resizeImage:40", label: "40%", value: "40" },
			{ name: "resizeImage:50", label: "50%", value: "50" },
			{ name: "resizeImage:75", label: "75%", value: "75" },
		],
	},
	simpleUpload: {
		uploadUrl: "https://api.growsharp.in/upload",
	},
	// ckfinder: {
	// 	uploadUrl: "https://api.growsharp.in/upload",
	// },
	table: {
		contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
	},
	language: "en",
};
