import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { ToastContainer, toast } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";
import dayjs from "dayjs";

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import parse from "html-react-parser";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

export default function EditTest() {
  const [questionModal, setQuestionModal] = useState(false);
  const location = useLocation();
  const Test = location.state.Test;
  const [File, setFile] = useState(null);
  const [FileEx, setFileEx] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (file && file.size > 1048576) {
      setFile(null);
      toast.error("File must be less than 1 MB");
      return false;
    }
    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("File have a valid extension (.jpg, .jpeg, .png).");
      setFile(null);
      return false;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const Image = reader.result.replace("data:", "").replace(/^.+,/, "");
      setFile(Image);
      setFileEx(fileExtension);
    };
    reader.readAsDataURL(event.target.files[0]);
  };
  const Error = (message) => {};
  const [questionCount, setQuestionCount] = useState(0);

  const handleChangeStart = (newValue) => {
    if (newValue.isValid()) {
      const isoFormattedTime = newValue.toISOString(); // Convert the time to ISO 8000 format
      setTest({ ...test, start_time: isoFormattedTime });
    }
  };

  function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  }
  const handleChangeEnd = (newValue) => {
    if (newValue.isValid()) {
      const isoFormattedTime = newValue.toISOString(); // Convert the time to ISO 8000 format
      setTest({ ...test, end_time: isoFormattedTime });
    }
  };

  const [subject_data, setSubject_Data] = useState([]);

  const [subjects, setSubjects] = React.useState([]);

  const [selectedSubject, setSelectedSubject] = useState([]);
  const [AllSubject, setAllSubject] = useState([]);

  const [types, setTypes] = useState([]);

  const [boards, setBoards] = useState([]);

  const [grades, setGrades] = useState([]);

  const [chapters, setChapters] = useState([]);

  const [questions, setQuestions] = useState([]);

  const token = localStorage.getItem("token");

  const [selectedQuestions, setSelectedQuestions] = useState(Test?.QueByTest);
  const navigate = useNavigate();
  const [callApi, setCallApi] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectAll, setSelectAll] = useState(false);

  const currentDate = dayjs();
  // const handleSelectAll = (event) => {
  //   const isChecked = event.target.checked;
  //   setSelectAll(isChecked);
  //   if (isChecked) {
  //     for (const question of questions) {
  //       if (question.subject == selectedSubject._id) {
  //         setSelectedQuestions((prevSelectedQuestions) => [
  //           ...prevSelectedQuestions,
  //           {
  //             questionId: question?._id,
  //             subjectId: question.subject,
  //             testId: Test?._id,
  //             question: question.question,
  //           },
  //         ]);
  //       }
  //     }
  //   } else {
  //     for (const question of questions) {
  //       if (question.subject == selectedSubject._id) {
  //         setSelectedQuestions((prevSelectedQuestions) =>
  //           prevSelectedQuestions?.filter(
  //             (selectedQuestion) =>
  //               selectedQuestion?.questionId !== question?._id
  //           )
  //         );
  //       }
  //     }
  //   }
  // };
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      // Add questions, ensuring no duplicates
      setSelectedQuestions((prevSelectedQuestions) => {
        const newSelectedQuestions = [...prevSelectedQuestions];

        for (const question of questions) {
          if (question.subject === selectedSubject._id) {
            const questionExists = newSelectedQuestions.some(
              (selectedQuestion) => selectedQuestion.questionId === question._id
            );

            if (!questionExists) {
              newSelectedQuestions.push({
                questionId: question._id,
                subjectId: question.subject,
                testId: Test?._id,
                question: question.question,
              });
            }
          }
        }

        return newSelectedQuestions;
      });
    } else {
      // Remove questions for the selected subject
      setSelectedQuestions((prevSelectedQuestions) =>
        prevSelectedQuestions.filter(
          (selectedQuestion) =>
            selectedQuestion.subjectId !== selectedSubject._id
        )
      );
    }
  };

  const [test, setTest] = useState({
    name: Test?.name,
    grade: Test?.grades._id,
    test_type: Test?.test_type?._id,
    board: Test?.boards?._id,
    total_questions: Test?.total_questions,
    duration: Test?.duration,
    marks: Test?.marks,
    negative_marks: Test?.negative_marks,
    total_marks: Test?.total_marks,
    start_time: Test?.start_time,
    end_time: Test?.end_time,
    image: File,
    imageEx: FileEx,
    subjects: Test?.subjects,
    subjectsData: Test?.subjectsData,
    questions: Test?.QueByTest,
    instructions: Test?.instructions,
  });
  const handleCheckboxChange = (event, question) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // Add the question to the selectedQuestions array
      setSelectedQuestions((prevSelectedQuestions) => [
        ...prevSelectedQuestions,
        {
          questionId: question?._id,
          subjectId: question.subject,
          testId: Test?._id,
          question: question.question,
        },
      ]);
    } else {
      // Remove the question from the selectedQuestions array
      setSelectedQuestions((prevSelectedQuestions) =>
        prevSelectedQuestions?.filter(
          (selectedQuestion) => selectedQuestion?.questionId !== question?._id
        )
      );
    }
  };
  const questions_data = {
    columns: [
      {
        label: (
          <>
            S.no&nbsp;{" "}
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
          </>
        ),
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Question Details",
        field: "question",
        sort: "asc",
        width: 150,
      },
      {
        label: "Board Name",
        field: "board_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Grade Name",
        field: "grade_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Subject",
        field: "subject",
        sort: "asc",
        width: 150,
      },
      {
        label: "Chapter",
        field: "chapter",
        sort: "asc",
        width: 150,
      },
    ],
    rows: questions
      ?.filter((question) => question?.subjects?._id === selectedSubject?._id)
      .map((question, index) => {
        return {
          s_no: (
            <th scope="row">
              <input
                type="checkbox"
                checked={selectedQuestions?.some(
                  (selectedQuestion) =>
                    selectedQuestion.questionId === question?._id
                )}
                onChange={(event) => handleCheckboxChange(event, question)}
              />
              &nbsp;{index + 1}
            </th>
          ),
          question: <td>{parse(`${question.question}`)}</td>,
          board_name: question?.boards?.name,
          subject: question?.subjects?.name,
          grade_name: question?.grades?.name,
          chapter: question?.chapters?.name,
        };
      }),
  };

  const fetchChapters = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}chapter/chapters`)
      .then((res) => {
        setChapters(res.data);
      })
      .catch((e) => {
        setChapters([]);
      });
  };

  const [filters, setFilters] = useState({
    board: "",
    grade: "",
    subject: "",
    chapter: "",
  });

  const [openAssign, setOpenAssign] = useState(false);

  let name, val;

  const inputHandler = (e) => {
    name = e.target?.name;
    val = e.target.value;
    setTest({ ...test, [name]: val });
  };

  const handleSubjectChange = (event) => {
    const {
      target: { value },
    } = event;
    setTest({
      ...test,
      subjects:
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value,
    });
    setSubjects(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const fetchSubjects = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}subject/subjects?board=${test.board}&grade=${test.grade}`
      )
      .then((res) => {
        const subjectids = [];
        const subjectnames = [];
        setQuestionCount(res.data.subjects);
        if (res.data?.subjects?.length > 0) {
          setAllSubject(res.data.subjects);
          res.data.subjects.map((subject) => {
            subjectids.push(subject?._id);
            subjectnames.push(subject?.name?.toLowerCase());
            // setSubjects(subjectnames);
            setSubject_Data(subjectnames);
          });
        } else {
          setSubjects([]);
          setSubject_Data([]);
        }
      })
      .catch((e) => {
        Error(e.data.message);
      });
  };

  const fetchQuestions = async () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}question/get-questions`;

    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }

    await axios
      .get(url, { headers: { jwt: token } })
      .then((res) => {
        setQuestions(res.data.Questions);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        localStorage.clear();
        navigate("/login");
      });
  };

  const fetchBoards = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {
        setBoards(res.data.Boards);
      })
      .catch((e) => {
        Error(e.data.message);
        setBoards([]);
      });
  };

  const fetchGrades = async () => {
    if (test.board) {
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}grade/get-grades-by-board/${test.board}`
        )
        .then((res) => {
          setGrades(res.data.grades);
        })
        .catch((e) => {
          setGrades([]);
        });
    }
  };

  const fetchTestTypes = async () => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}test-type/test-types`)
      .then((res) => {
        setTypes(res.data.TestType);
      })
      .catch((e) => {
        setTypes([]);
      });
  };

  const editTest = async () => {
    var SubIds = [];
    AllSubject?.filter((sub) =>
      test.subjects.includes(sub?.name.toLowerCase())
    ).map((sub) => {
      return SubIds.push(sub?._id);
    });
    if (!SubIds.length > 0) {
      SubIds = test.subjects;
    }
    const filteredData = selectedQuestions.filter((item) =>
      SubIds.includes(item.subjectId)
    );
    if (filteredData.length != parseInt(test.total_questions)) {
      toast.error(
        "Please assign " +
          parseInt(test.total_questions) +
          " Questions you assign " +
          filteredData.length +
          " Questions"
      );
      return false;
    }
    // return;
    await axios
      .put(`${process.env.REACT_APP_BASE_URL}test/edit-test/${Test?._id}`, {
        name: test?.name,
        grade: test.grade,
        test_type: test?.test_type ? test?.test_type : Test.test_type,
        board: test.board,
        total_questions: test.total_questions,
        duration: test.duration,
        marks: test.marks,
        negative_marks: test.negative_marks,
        total_marks: test.total_marks,
        start_time: test.start_time,
        end_time: test.end_time,
        image: File,
        imageEx: FileEx,
        subjects: SubIds,
        questions: filteredData,
        instructions: test.instructions,
      })
      .then((res) => {
        navigate("/tests-list");
      })
      .catch((e) => {
        Error(e.data.responseText);
      });
  };
  useEffect(() => {
    fetchBoards();
  }, []);

  useEffect(() => {
    fetchGrades();
  }, [test.board]);

  useEffect(() => {
    fetchSubjects();
  }, [test.board, test.grade]);

  useEffect(() => {
    fetchTestTypes();
    fetchChapters();
  }, [callApi]);

  useEffect(() => {
    if (Test?.subjectsData) {
      Test?.subjectsData.map((subject) => {
        setSubject_Data((preValue) => {
          return [...preValue, subject?.name?.toLowerCase()];
        });
        setSubjects((preValue) => {
          return [...preValue, subject?.name?.toLowerCase()];
        });
      });
    }
  }, []);

  useEffect(() => {
    fetchQuestions();
  }, [callApi, selectedSubject]);

  useEffect(() => {
    const checkLength = selectedQuestions.filter(
      (question) => question?.subjectId === selectedSubject._id
    ).length;
    const checkAll = questions?.filter(
      (question) => question?.subjects?._id === selectedSubject?._id
    ).length;
  });

  return (
    <PageLayout>
      <ToastContainer autoClose={1000} />
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Compose Test"}></Breadcrumb>
            <Link to="/tests-list">
              <Button className="btn btn-primary">View Tests</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={2}>
                <CardHeader title="Test Name" />
                <TextField
                  id="outlined-required"
                  defaultValue=""
                  sx={{ width: "100%" }}
                  name="name"
                  value={test?.name}
                  onChange={inputHandler}
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Choose Test Type" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Test Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Test Type"
                    name="test_type"
                    value={test?.test_type ? test?.test_type : Test?.test_type}
                    onChange={inputHandler}
                  >
                    {types?.length > 0 &&
                      types.map((type) => {
                        return (
                          <MenuItem key={type?._id} value={type?._id}>
                            {type?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={2}>
                <CardHeader title="Total Questions" />
                <TextField
                  id="outlined-required"
                  defaultValue=""
                  sx={{ width: "100%" }}
                  name="total_questions"
                  value={test.total_questions}
                  onChange={inputHandler}
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Duration(in mins.)" />
                <TextField
                  id="outlined-required"
                  defaultValue=""
                  sx={{ width: "100%" }}
                  name="duration"
                  value={test.duration}
                  onChange={inputHandler}
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Marks Per Question" />
                <TextField
                  id="outlined-required"
                  defaultValue=""
                  sx={{ width: "100%" }}
                  name="marks"
                  value={test.marks}
                  onChange={inputHandler}
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Negative Marks" />
                <TextField
                  id="outlined-required"
                  defaultValue=""
                  sx={{ width: "100%" }}
                  name="negative_marks"
                  value={test.negative_marks}
                  onChange={inputHandler}
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Total Marks" />
                <TextField
                  id="outlined-required"
                  defaultValue=""
                  sx={{ width: "100%" }}
                  name="total_marks"
                  value={test.total_questions * test.marks}
                  onChange={inputHandler}
                />
              </Col>

              <Col xl={3}>
                <CardHeader title="Start Time" />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={test.start_time}
                    onChange={handleChangeStart}
                    renderInput={(params) => <TextField {...params} />}
                    name="start_time"
                    minDateTime={currentDate}
                  />
                </LocalizationProvider>
              </Col>

              <Col xl={3}>
                <CardHeader title="End Time" />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={test.end_time}
                    onChange={handleChangeEnd}
                    renderInput={(params) => <TextField {...params} />}
                    name="end_time"
                    minDateTime={currentDate}
                  />
                </LocalizationProvider>
              </Col>

              <Col xl={2}>
                <CardHeader title="Board" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Board</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Board"
                    name="board"
                    value={test?.board}
                    onChange={inputHandler}
                  >
                    {boards?.length > 0 &&
                      boards.map((board) => {
                        return (
                          <MenuItem key={board?._id} value={board?._id}>
                            {board?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={2}>
                <CardHeader title="Grade" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Grade"
                    name="grade"
                    value={test.grade}
                    onChange={inputHandler}
                  >
                    {grades?.length > 0 &&
                      grades.map((grade, index) => {
                        return (
                          <MenuItem key={index} value={grade?._id}>
                            {grade?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={3}>
                <CardHeader title="Subjects" />
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={subjects}
                    onChange={handleSubjectChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {subject_data.map((name, index) => (
                      <MenuItem key={index} value={name}>
                        <Checkbox checked={subjects.includes(name)} />
                        <ListItemText primary={capitalizeFirstLetter(name)} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={3}>
                <CardHeader title="Choose Image" />
                <TextField
                  id="required"
                  type="file"
                  defaultValue=""
                  placeholder="Enter Price of Package"
                  onChange={handleFileChange}
                  sx={{ width: "100%" }}
                  name="image"
                />
              </Col>

              <Col xl={4}>
                <CardHeader title="Instructions" />
                <TextField
                  id="outlined-textarea"
                  placeholder="Enter Test Instructions..."
                  multiline
                  sx={{ width: "100%" }}
                  name="instructions"
                  value={test.instructions}
                  onChange={inputHandler}
                />
              </Col>

              {test?.subjects?.length > 0 && (
                <Col xl={12}>
                  <Box className="mc-card">
                    <CardHeader title="Subjects List" />
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">S.no</th>
                                <th scope="col">Subject</th>
                                <th scope="col">No. Of Questions</th>
                                <th scope="col">Manage Questions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {questionCount?.length > 0 &&
                                questionCount?.map((subject, index) => {
                                  return (
                                    <>
                                      {subjects.includes(
                                        subject?.name.toLowerCase()
                                      ) && (
                                        <tr>
                                          <th scope="row">
                                            &nbsp; {index + 1}
                                          </th>
                                          <td>
                                            {capitalizeFirstLetter(
                                              subject?.name
                                            )}
                                          </td>
                                          <td>{subject?.numberOfQuestions}</td>
                                          <td>
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-evenly",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                  setOpenAssign(true);
                                                  setSelectedSubject(subject);
                                                }}
                                              >
                                                Assign
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  setQuestionModal(true);

                                                  setSelectedSubject(subject);
                                                }}
                                                className="btn btn-primary"
                                              >
                                                View
                                              </Button>
                                              <Modal
                                                aria-labelledby="modal-title"
                                                aria-describedby="modal-desc"
                                                open={questionModal}
                                                onClose={() => {
                                                  setQuestionModal(false);
                                                  setFilters({});
                                                }}
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  zIndex: "1000",
                                                }}
                                              >
                                                <Sheet
                                                  variant="outlined"
                                                  sx={{
                                                    width: "50%",
                                                    borderRadius: "md",
                                                    p: 3,
                                                    boxShadow: "lg",
                                                    bgcolor: "#fff",
                                                  }}
                                                >
                                                  <ModalClose
                                                    variant="outlined"
                                                    sx={{
                                                      top: "calc(-1/4 * var(--IconButton-size))",
                                                      right:
                                                        "calc(-1/4 * var(--IconButton-size))",
                                                      boxShadow:
                                                        "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                                                      borderRadius: "50%",
                                                      bgcolor: "#eee",
                                                    }}
                                                  />
                                                  <Typography
                                                    id="basic-modal-dialog-title"
                                                    component="h2"
                                                    level="inherit"
                                                    fontSize="1.25em"
                                                    mb={1}
                                                  >
                                                    Assigned Questions
                                                  </Typography>

                                                  <Stack spacing={2}>
                                                    <div
                                                      style={{
                                                        marginTop: "2rem",
                                                      }}
                                                    >
                                                      <CardHeader
                                                        title={
                                                          selectedSubject?.name
                                                        }
                                                      />

                                                      <ol
                                                        type="1"
                                                        style={{
                                                          display: "block",
                                                          listStyle: "number",
                                                          marginLeft: "1rem",
                                                        }}
                                                      >
                                                        {selectedQuestions
                                                          ?.filter(
                                                            (question) =>
                                                              question?.subjectId ===
                                                              selectedSubject._id
                                                          )
                                                          .map(
                                                            (
                                                              question,
                                                              index
                                                            ) => {
                                                              return (
                                                                <li
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    flexDirection:
                                                                      "row",
                                                                    width:
                                                                      "100%",
                                                                    justifyContent:
                                                                      "space-between",
                                                                    marginBottom:
                                                                      "0.5rem",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    {index + 1}
                                                                    .&nbsp;
                                                                    {parse(
                                                                      `${question?.question}`
                                                                    )}
                                                                  </div>
                                                                  <i
                                                                    style={{
                                                                      fontSize:
                                                                        "1.2rem",
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                      setSelectedQuestions(
                                                                        (
                                                                          prevSelectedQuestions
                                                                        ) =>
                                                                          prevSelectedQuestions.filter(
                                                                            (
                                                                              selectedQuestion
                                                                            ) =>
                                                                              selectedQuestion !==
                                                                              question
                                                                          )
                                                                      );
                                                                    }}
                                                                    className="fas fa-trash"
                                                                  ></i>
                                                                </li>
                                                              );
                                                            }
                                                          )}
                                                      </ol>
                                                    </div>
                                                  </Stack>
                                                </Sheet>
                                              </Modal>

                                              <div
                                                style={{
                                                  width: "2.5rem",
                                                  height: "2.5rem",
                                                  borderRadius: "50%",
                                                  color: "#fff",
                                                  background: "#ff6b6b",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {
                                                  selectedQuestions.filter(
                                                    (question) =>
                                                      question?.subjectId ===
                                                      subject?._id
                                                  ).length
                                                }
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: "2rem",
                      }}
                    >
                      <Button
                        className="btn btn-primary mb-2"
                        onClick={(e) => {
                          e.preventDefault();
                          editTest();
                        }}
                      >
                        Edit Test
                      </Button>
                    </div>
                  </Box>
                </Col>
              )}
            </Row>
          </Box>
        </Col>

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openAssign}
          onClose={() => setOpenAssign(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "90%",
              height: "40rem",
              overflowY: "scroll",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Col xl={12}>
              <Box className="mc-card">
                <Row xs={1} md={1} xl={1}>
                  <Col xl={2}>
                    <div style={{ marginTop: "2rem" }}>
                      <CardHeader title="Select Board" />
                      <FormControl sx={{ width: 150 }}>
                        <InputLabel id="demo-simple-select-label">
                          Board
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Board"
                          value={filters.board}
                          onChange={(e) =>
                            setFilters({ ...filters, board: e.target.value })
                          }
                        >
                          <MenuItem value={""}>None</MenuItem>
                          {boards?.length > 0 &&
                            boards.map((board) => {
                              return (
                                <MenuItem value={board?.name}>
                                  {board?.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>

                  <Col xl={2}>
                    <div style={{ marginTop: "2rem" }}>
                      <CardHeader title="Select Grade" />
                      <FormControl sx={{ width: 150 }}>
                        <InputLabel id="demo-simple-select-label">
                          Grade
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          label="Grade"
                          id="demo-simple-select"
                          value={filters.grade}
                          onChange={(e) =>
                            setFilters({ ...filters, grade: e.target.value })
                          }
                        >
                          <MenuItem value={""}>None</MenuItem>
                          {grades?.length > 0 &&
                            grades.map((grade) => {
                              return (
                                <MenuItem value={grade?.name}>
                                  {grade?.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div style={{ marginTop: "2rem" }}>
                      <CardHeader title="Select Chapter" />
                      <FormControl sx={{ width: 150 }}>
                        <InputLabel id="demo-simple-select-label">
                          Chapter
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          label="Chapter"
                          id="demo-simple-select"
                          value={filters.chapter}
                          onChange={(e) =>
                            setFilters({ ...filters, chapter: e.target.value })
                          }
                        >
                          <MenuItem value={""}>None</MenuItem>
                          {chapters?.length > 0 &&
                            chapters.map((chapter) => {
                              return (
                                <MenuItem value={chapter?.name}>
                                  {chapter?.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>

                  <Col xl={2}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "5.25rem",
                      }}
                    >
                      <Button
                        className="btn btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          fetchQuestions();
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </Col>

                  <Col xl={12}>
                    <Box className="mc-card">
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "1rem",
                          marginRight: "2.2rem",
                        }}
                      >
                        <CardHeader title="Questions List" />
                        <Button
                          className="btn btn-primary"
                          onClick={() => {
                            setOpenAssign(false);
                            setSelectAll(false);
                          }}
                        >
                          Import Question
                        </Button>
                      </div>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <MDBDataTable
                              striped
                              bordered
                              small
                              data={questions_data}
                              disableRetreatAfterSorting={true}
                              style={{ marginTop: "1rem" }}
                            />
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
